import { Button, ThemeProvider, Typography } from "@mui/material";
import React from "react";
import theme from "../../../theme";
import useStyles from "./CaptureStyles";
import Camera from "../../../Assets/Icons/Camera.png";
import { useStoreActions } from "easy-peasy";

const CaptureButton = ({ title, picIndex }) => {
  const classes = useStyles();
  const setIsCameraModalOpen = useStoreActions(
    (actions) => actions.setCameraModalState
  );

  return (
    <ThemeProvider theme={theme}>
      <Button
        className={classes.button}
        onClick={() => setIsCameraModalOpen({ index1: picIndex[0],index2:picIndex[1], value: true })}
      >
        <img src={Camera} alt="Camera Icon" className={classes.buttonIcon} />
        <Typography variant="titleSmall">{title}</Typography>
      </Button>
    </ThemeProvider>
  );
};

export default CaptureButton;
