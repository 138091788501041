import React from "react";
import useStyles from "./style";
import { Typography } from "@mui/material";
import MediaIcon from "../../Assets/Icons/Media.png";
import Locale from "../../locale";
import { useNavigate } from "react-router-dom";

const ContainerTitle = ({ title, icon, images }) => {
  const navigate = useNavigate();
  const locale = Locale();
  const classes = useStyles();
  console.log("images",images);
  
  return (
    <div className={classes.mainContainer}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          marginBottom: "0.25rem",
        }}
      >
        <div className={classes.checkIconContainer}>
          <img alt={title} className={classes.checkIcon} src={icon} />
        </div>
        <Typography variant="titleMedium" className={classes.subtitle}>
          {title}
        </Typography>
      </div>
      <div
        className={classes.ViewButton}
        style={{ visibility: images ? "visible" : "hidden" }}
        onClick={() => {
          navigate("/imageview", {
            state: { images: images ? images : [] },
          });
        }}
      >
        <Typography variant="titleSmall">{locale.View}</Typography>
        <img
          src={MediaIcon}
          className={classes.checkIcon}
          style={{ marginLeft: "0.5rem" }}
        />
      </div>
    </div>
  );
};

export default ContainerTitle;
