import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import theme, { globalStyles } from "./../../theme";
// import Locale from "./../../locale";
import Banner from "../../Components/Top_banner/Banner";
import MainPageTable from "../../Components/MainPageTable/MainPageTable";
import MainPageShop from "../../Components/MainPageShop/MainPageShop";
import HomeAnalyze from "../../Components/Buttons/HomeAnalyze/AnalyzeButton";

const MainPage = () => {
  return (
    <ThemeProvider theme={theme}>
      <Banner />
      <MainPageShop />
      <HomeAnalyze />
      <MainPageTable />
    </ThemeProvider>
  );
};

export default MainPage;
