import React, { useEffect, useRef, useState } from "react";
import { ThemeProvider } from "@mui/material/styles";
// import { Typography, Button } from "@mui/material";
import theme from "./theme"; // Import the theme
import MainPage from "./Pages/Main_page/main";
import Navbar from "./Components/Header/header";
import Footer from "./Components/Footer/footer";
import { StoreProvider } from "easy-peasy";
import MainStore from "./Redux/MainStore";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Card from "./Components/Card/card";
import Analysis from "./Pages/Dashboard/Analysis/Analysis";
import SingleItem from "./Pages/Dashboard/SingleItem/SingleItem";
import ScrollToTop from "./Components/ScrollToTop/scrollTop";
import ViewCapture from "./Pages/Dashboard/ViewCapture/ViewCapture";
import ImageViewer from "./Pages/ImageViewer/ImageViewer";

function App() {
  const [navbarHeight, setNavbarHeight] = useState(0);
  const [footerHeight, setFooterHeight] = useState(0);
  const navbarRef = useRef(null);
  const footerRef = useRef(null);

  useEffect(() => {
    // Function to update the heights
    const updateHeights = () => {
      if (navbarRef.current) {
        setNavbarHeight(navbarRef.current.offsetHeight);
      }
      if (footerRef.current) {
        setFooterHeight(footerRef.current.offsetHeight);
      }
    };

    // Initial height set
    updateHeights();

    // Optional: Update heights on window resize
    window.addEventListener("resize", updateHeights);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateHeights);
    };
  }, [navbarHeight, footerHeight]);
  return (
    <StoreProvider store={MainStore}>
      <ThemeProvider theme={theme}>
        <div style={{ minHeight: "100vh" }}>
          <div ref={navbarRef}>
            <Navbar />
          </div>
          <Router>
            <ScrollToTop />
            <Routes>
              <Route
                path="/"
                element={
                  <MainPage
                    navbarHeight={navbarHeight}
                    footerHeight={footerHeight}
                  />
                }
              />
              <Route path="/about" element={<MainPage />} />
              <Route path="/card" element={<Card />} />
              <Route path="/analysis" element={<Analysis />} />
              <Route path="/analysis/singleItem" element={<SingleItem />} />
              <Route path="/analysis/viewCapture" element={<ViewCapture />} />
              <Route path="/imageview" element={<ImageViewer />} />
            </Routes>
          </Router>
          <Footer />
        </div>
      </ThemeProvider>
    </StoreProvider>
  );
}

export default App;
