import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  ViewButton: {
    display: "flex",
    flexDirection: "row",
    padding: "0.5rem 1rem",
    color: theme.palette.primary[400],
    cursor: "pointer",
    zIndex: 1,
    borderRadius: "0.5rem",
    border: `1px solid ${theme.palette.primary[300]} !important`,
  },
  checkIcon: {
    width: "1.25rem",
    height: "1.25rem",
  },
  checkIconContainer: {
    margin: "0 0.5rem 0 0",
    padding: "0.375rem",
    backgroundColor: theme.palette.primary[100],
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "25%",
  },
  subtitle: {
    color: theme.palette.primary[500],
  },
}));

export default useStyles;
