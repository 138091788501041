import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    postCameraButtons:{
        alignItems: "center",
        display: 'flex',
        flexDirection: 'row !important',
        width: "100% !important",
        textTransform: "none !important",
        margin: "1.25rem 0 0 !important",
    },
    recaptureIcon: {
        width: "1.25rem",
        height: "1.25rem",
        margin: "0.5rem",
      },
}))

export default useStyles;