import React, { useEffect, useRef } from "react";
import theme from "../../../theme";
import { ThemeProvider } from "@mui/styles";
import { Typography } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import useStyles from "./chillerModalStyle";
import { useStoreState, useStoreActions } from "easy-peasy";
import Locale from "../../../locale";
import Card from "../../Card/card";
import CloseIcon from "../../../Assets/Icons/Close.png";
import RestApi from "../../../Utils/Backend";

const ChillerModal = ({ open, onClose }) => {
  const locale = Locale();
  const classes = useStyles();
  const { images, sessionId } = useStoreState((state) => ({
    images: state.images,
    sessionId: state.sessionId,
  }));
  const { setPlanogramData, setPosmData } = useStoreActions((actions) => ({
    setPlanogramData: actions.setPlanogramData,
    setPosmData: actions.setPosmData,
  }));

  const hasMounted = useRef({ planogram: false, posm: false });

  useEffect(() => {
    const getPlanogram = async () => {
      const planogramData = await RestApi().uploadImage(
        "/backend/get_planogram/",
        sessionId,
        "chiller",
        images[0][0]
      );
      setPlanogramData(planogramData);
    };

    if (hasMounted.current.planogram && images[0][0]) {
      getPlanogram();
    } else {
      hasMounted.current.planogram = true;
    }
  }, [images[0][0]]);

  useEffect(() => {
    const getPOSM = async () => {
      const posmData = await RestApi().uploadImage(
        "/backend/get_posm/",
        sessionId,
        "chiller",
        images[0][1]
      );
      setPosmData(posmData);
    };

    if (hasMounted.current.posm && images[0][1]) {
      getPOSM();
    } else {
      hasMounted.current.posm = true;
    }
  }, [images[0][1]]);

  const modalStyle = {
    outline: "none",
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    maxWidth: "30rem",
    backgroundColor: "white",
    borderRadius: "1rem",
    margin: "0 auto",
    padding: "1.5rem",
    height: "auto",
  };

  const headerStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  };

  const optionsStyle = {
    display: "flex",
    flexDirection: "column",
    gap: "0rem",
  };

  return (
    <ThemeProvider theme={theme}>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={onClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <div style={modalStyle}>
            <div style={headerStyle}>
              <Typography id="transition-modal-title" variant="h3">
                Chiller
              </Typography>
              <img
                alt="close"
                src={CloseIcon}
                width="16px"
                height="16px"
                style={{ cursor: "pointer" }}
                onClick={onClose}
              />
            </div>
            <Typography
              id="transition-modal-description"
              variant="bodySmall"
              className={classes.modalText}
            >
              {locale.ChillerModalText}
            </Typography>
            <div style={optionsStyle}>
              <Card
                number="1"
                title={locale.ChillerOpenText}
                capture
                childTitle={locale.ChillerOpenText}
                picIndex={[0, 0]}
              />
              <Card
                number="2"
                title={locale.ChillerCloseText}
                capture
                childTitle={locale.ChillerCloseText}
                picIndex={[0, 1]}
              />
            </div>
          </div>
        </Fade>
      </Modal>
    </ThemeProvider>
  );
};

export default ChillerModal;
