import {
  ThemeProvider,
  Typography,
  Tabs,
  Tab,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Collapse,
  IconButton,
} from "@mui/material";

import React, { useState } from "react";
import theme from "../../../theme";
import useStyles from "./SingleItemStyles";
import { globalStyles } from "../../../theme";
import ShelfIcon from "../../../Assets/Icons/Shelf.png";
import MoneyIcon from "../../../Assets/Icons/Money.png";
import StarIcon from "../../../Assets/Icons/Star.png";
import { PieChart } from "@mui/x-charts/PieChart";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import RedBullCan from "../../../Assets/Images/redbull.png";
import ArrowDown from "../../../Assets/Icons/ArrowDown.png";
import GoBackHeader from "../../../Components/GoBackHeader/GoBackHeader";
import ContainerTitle from "../../../Components/ContainerTitle/ContainerTitle";
import Locale from "../../../locale";

import DynamicPieChart from "./DynamicPieChart";
import BrandTable from "./BrandTable";

// const data = [
//   { label: "Red Bull", facing: 6, value: "14.33", color: "#194FF0" },
//   { label: "Power Root", facing: 8, value: "29.46", color: "#08E0DB" },
//   { label: "Warrior", facing: 5, value: "23.47", color: "#02207D" },
//   { label: "Hivetown", facing: 2, value: "20.30", color: "#5D5FEF" },
//   { label: "Monster", facing: 2, value: "12.50", color: "#B872FF" },
// ];

const data = [
  {
    label: "Red Bull",
    facing: 6,
    value: 26.06,
    color: "#FF0000", // Red for Red Bull
    details: [
      { name: "Red Bull Original", facing: 4, value: 66.67, color: "#FF6F61" },
      {
        name: "Red Bull Sugar-Free",
        facing: 2,
        value: 33.33,
        color: "#FF9C92",
      },
    ],
  },
  {
    label: "Power Root",
    facing: 8,
    value: 34.78,
    color: "#00BCD4", // Cyan for Power Root
    details: [
      { name: "Extra Honey 250ml", facing: 4, value: 50, color: "#80DEEA" },
      { name: "Honey Dates 250ml", facing: 4, value: 50, color: "#4DD0E1" },
    ],
  },
  {
    label: "Warrior",
    facing: 5,
    value: 21.73,
    color: "#000080", // Navy for Warrior
    details: [
      { name: "Warrior Original", facing: 3, value: 60, color: "#3F51B5" },
      { name: "Warrior Energy 250ml", facing: 2, value: 40, color: "#7986CB" },
    ],
  },
  {
    label: "Hivetown",
    facing: 2,
    value: 8.69,
    color: "#800080", // Purple for Hivetown
    details: [
      { name: "Hivetown Classic", facing: 1, value: 50, color: "#BA68C8" },
      { name: "Hivetown Premium", facing: 1, value: 50, color: "#9C27B0" },
    ],
  },
  {
    label: "Monster",
    facing: 2,
    value: 8.69,
    color: "#800000", // Maroon for Monster
    details: [
      {
        name: "Monster Energy Original",
        facing: 1,
        value: 50,
        color: "#EF5350",
      },
      { name: "Monster Zero Sugar", facing: 1, value: 50, color: "#D32F2F" },
    ],
  },
];

const brandLogos = {
  "Red Bull": "https://www.svgrepo.com/show/303227/redbull-logo.svg",
  "Power Root":
    "https://powerroot.com.my/wp-content/uploads/2022/09/powerRoot-logo.png",
  Warrior:
    "https://www.nicepng.com/png/full/430-4302949_ultimate-warrior-logo-png-download-warrior-energy-drink.png",
  Hivetown:
    "https://www.hivetown.co/wp-content/uploads/2024/09/hivetown-logotype.svg",
  Monster:
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR0amANxWF3tT5svq1bThQ34epbMyjZXWzasA&s",
};

const tabData = [
  {
    category: "150ml",
    content: [
      {
        product: "Competitor A",
        price: "14.52",
        change_direction: "+",
        change_percentage: 68,
      },
      {
        product: "Competitor B",
        price: "16.80",
        change_direction: "+",
        change_percentage: 60,
      },
      {
        product: "Red Bull",
        price: "9.00",
        change_direction: "",
        change_percentage: 0,
        main_product: true,
      },
      {
        product: "Competitor F",
        price: "17.82",
        change_direction: "-",
        change_percentage: 20,
      },
      {
        product: "Competitor D",
        price: "7.13",
        change_direction: "-",
        change_percentage: 12,
      },
    ],
  },
  {
    category: "250ml",
    content: [
      {
        product: "Competitor A",
        price: "14.52",
        change_direction: "+",
        change_percentage: 68,
      },
      {
        product: "Competitor B",
        price: "16.80",
        change_direction: "+",
        change_percentage: 60,
      },
      {
        product: "Red Bull",
        price: "9.00",
        change_direction: "",
        change_percentage: 0,
        main_product: true,
      },
      {
        product: "Competitor F",
        price: "17.82",
        change_direction: "-",
        change_percentage: 20,
      },
      {
        product: "Competitor D",
        price: "7.13",
        change_direction: "-",
        change_percentage: 12,
      },
    ],
  },
];

const skuData = [
  {
    competitor: {
      product: RedBullCan,
      text: "Power Root Extra HoneyTongkat Ali and Ginseng 250ml",
    },
    oppurtunity: { product: RedBullCan, text: "Red Bull Plus 250ml" },
  },
  {
    competitor: {
      product: RedBullCan,
      text: "Hivetown Acacia Honey Sparkling 320ml",
    },
    oppurtunity: { product: RedBullCan, text: "Red Bull Apple Muscat 250ml" },
  },
  {
    competitor: { product: RedBullCan, text: "Warrior Strawberry 320ml" },
    oppurtunity: { product: RedBullCan, text: "Red Bull Gold 325ml" },
  },
];

const TabPanel = ({ children, value, index, theme2 }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      key={index}
      dir={theme2.direction}
    >
      {value === index && (
        <Box sx={{ mt: 2 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const SingleItem = () => {
  const globalstyles = globalStyles();
  const classes = useStyles();
  const theme2 = useTheme();
  const locale = Locale();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSwipeChange = (index) => {
    setValue(index);
  };

  const [selectedBrand, setSelectedBrand] = useState(null);

  return (
    <ThemeProvider theme={theme}>
      <GoBackHeader text={locale.EnergyDrink} location={"/analysis"} />
      <div className={globalstyles.mainContainer}>
        <div className={globalstyles.innerContainer}>
          <ContainerTitle title={locale.ShareofShelf} icon={ShelfIcon} />
          <div className={classes.chartContainer}>
            <DynamicPieChart
              data={data}
              selectedBrand={selectedBrand}
              brandLogos={brandLogos}
            />
          </div>

          <BrandTable
            data={data}
            selectedBrand={selectedBrand}
            setSelectedBrand={setSelectedBrand}
          />
        </div>
        <div className={globalstyles.innerContainer}>
          <ContainerTitle title={locale.Pricing} icon={MoneyIcon} />
          <Box sx={{ width: "100%", margin: "1rem 0" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              variant={tabData.length > 2 ? "scrollable" : "fullWidth"}
              scrollButtons="auto"
              allowScrollButtonsMobile
            >
              {tabData.map((tab, index) => (
                <Tab
                  key={index}
                  sx={{
                    textTransform: "none",
                    "&.Mui-selected": {
                      color: `${theme.palette.primary[400]} !important`, // Selected tab color
                    },
                  }}
                  label={
                    <Typography variant="titleSmall" component="span">
                      {tab.category}
                    </Typography>
                  }
                />
              ))}
            </Tabs>
            <SwipeableViews
              axis={theme2.direction === "rtl" ? "x-reverse" : "x"}
              index={value}
              onChangeIndex={handleSwipeChange}
            >
              {tabData.map((tab, contentIndex) => (
                <TabPanel
                  key={contentIndex}
                  value={value}
                  index={contentIndex}
                  theme2={theme2}
                >
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            style={{
                              backgroundColor: theme.palette.neutral[200],
                            }}
                          >
                            <Typography
                              variant="bodySmall"
                              style={{
                                color: theme.palette.neutral[400],
                                textAlign: "center",
                              }}
                            >
                              {locale.Product}
                            </Typography>
                          </TableCell>
                          <TableCell
                            style={{
                              textAlign: "center",
                              backgroundColor: theme.palette.neutral[200],
                            }}
                          >
                            <Typography
                              variant="bodySmall"
                              style={{
                                color: theme.palette.neutral[400],
                                textAlign: "center",
                              }}
                            >
                              {locale.Price}
                            </Typography>
                          </TableCell>
                          <TableCell
                            style={{
                              textAlign: "center",
                              backgroundColor: theme.palette.neutral[200],
                            }}
                          ></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {tab.content &&
                          tab.content.map((row, index) => (
                            <TableRow key={index}>
                              <TableCell>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Typography
                                    variant="bodySmall"
                                    style={{
                                      color: row.main_product
                                        ? theme.palette.primary[400]
                                        : theme.palette.neutral[700],
                                    }}
                                  >
                                    {row.product}
                                  </Typography>
                                </div>
                              </TableCell>
                              <TableCell style={{ textAlign: "center" }}>
                                <Typography
                                  variant="titleSmall"
                                  style={{
                                    color: row.main_product
                                      ? theme.palette.primary[400]
                                      : row.change_direction === "+"
                                      ? theme.palette.success[300]
                                      : theme.palette.error[300],
                                  }}
                                >
                                  ${row.price}
                                </Typography>
                              </TableCell>
                              <TableCell style={{ textAlign: "center" }}>
                                {row.change_percentage === 0 ? (
                                  ""
                                ) : (
                                  <Typography
                                    variant="caption"
                                    className={
                                      row.change_direction === "+"
                                        ? classes.tabStatus2
                                        : classes.tabStatus0
                                    }
                                  >
                                    {row.change_direction}
                                    {row.change_percentage}%
                                  </Typography>
                                )}
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </TabPanel>
              ))}
            </SwipeableViews>
          </Box>
        </div>
        <div className={globalstyles.innerContainer}>
          <ContainerTitle title={locale.SKUDistOpp} icon={StarIcon} />
          <div>
            {skuData.map((table, index) => (
              <TableContainer
                key={index}
                className={globalstyles.greyContainer}
              >
                <Table style={{ width: "auto" }}>
                  <TableBody>
                    <TableRow>
                      <TableCell
                        style={{ borderBottom: "none" }}
                        className={classes.tableCell}
                      >
                        <img
                          alt="red bull can"
                          src={RedBullCan}
                          className={classes.sku_image}
                        />
                      </TableCell>
                      <TableCell
                        style={{ borderBottom: "none" }}
                        className={classes.tableCell}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "flex-start",
                            flexDirection: "column",
                          }}
                        >
                          <Typography
                            variant="caption"
                            style={{ color: theme.palette.neutral[400] }}
                          >
                            {locale.CompFound}
                          </Typography>
                          <Typography variant="titleSmall">
                            {table.competitor.text}
                          </Typography>
                        </div>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={{
                          borderBottom: "none",
                          textAlign: "center",
                          padding: 0,
                        }}
                      >
                        <img
                          src={ArrowDown}
                          alt="arrow down"
                          className={classes.checkIcon}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={{ borderBottom: "none" }}
                        className={classes.tableCell}
                      >
                        <img
                          alt="red bull can"
                          src={RedBullCan}
                          className={classes.sku_image}
                        />
                      </TableCell>
                      <TableCell
                        style={{ borderBottom: "none" }}
                        className={classes.tableCell}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "flex-start",
                            flexDirection: "column",
                          }}
                        >
                          <Typography
                            variant="caption"
                            style={{ color: theme.palette.neutral[400] }}
                          >
                            {locale.Oppurtunity}
                          </Typography>
                          <Typography
                            variant="titleSmall"
                            style={{ color: "#0035D4" }}
                          >
                            {table.oppurtunity.text}
                          </Typography>
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            ))}
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default SingleItem;
