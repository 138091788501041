import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Collapse,
  IconButton,
  Typography,
  Box,
} from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import React, { useState } from "react";

import theme from "../../../theme";
import Locale from "../../../locale";
import useStyles from "./SingleItemStyles";
import { globalStyles } from "../../../theme";
import { useTheme } from "@mui/material/styles";

const BrandTable = ({ data, selectedBrand, setSelectedBrand }) => {
  const globalstyles = globalStyles();
  const classes = useStyles();
  const theme2 = useTheme();
  const locale = Locale();
  const [openRowIndex, setOpenRowIndex] = useState(null); // Track only one open row

  const toggleRow = (index, brand) => {
    if (openRowIndex === index) {
      // If the clicked row is already open, close it
      setOpenRowIndex(null);
      setSelectedBrand(null);
    } else {
      // Open the new row and close any previously open rows
      setOpenRowIndex(index);
      setSelectedBrand(brand);
    }
  };

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{ textAlign: "center", borderBottom: "none" }} />
            <TableCell style={{ textAlign: "center", borderBottom: "none" }}>
              <Typography
                variant="bodySmall"
                style={{
                  color: theme.palette.neutral[400],
                  textAlign: "center",
                }}
              >
                {locale.Facing}
              </Typography>
            </TableCell>
            <TableCell style={{ textAlign: "center", borderBottom: "none" }}>
              <Typography
                variant="bodySmall"
                style={{ color: theme.palette.neutral[400] }}
              >
                {locale.Share}
              </Typography>
            </TableCell>
            <TableCell style={{ borderBottom: "none" }} />
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <React.Fragment key={row.label}>
              {/* Main Row */}
              <TableRow>
                <TableCell
                  style={{ textAlign: "center", borderBottom: "none" }}
                  className={classes.tableCell}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div
                      style={{
                        width: 16,
                        height: 16,
                        backgroundColor: row.color,
                        borderRadius: "50%",
                        marginRight: 8,
                      }}
                    />
                    <Typography
                      variant="bodySmall"
                      style={{ color: theme.palette.primary[400] }}
                    >
                      {row.label}
                    </Typography>
                  </div>
                </TableCell>
                <TableCell
                  style={{ textAlign: "center", borderBottom: "none" }}
                  className={classes.tableCell}
                >
                  <Typography variant="titleSmall">{row.facing}</Typography>
                </TableCell>
                <TableCell
                  style={{ textAlign: "center", borderBottom: "none" }}
                  className={classes.tableCell}
                >
                  <Typography variant="titleSmall">{row.value}%</Typography>
                </TableCell>
                <TableCell
                  style={{ textAlign: "center", borderBottom: "none" }}
                >
                  <IconButton onClick={() => toggleRow(index, row.label)}>
                    {openRowIndex === index ? (
                      <KeyboardArrowUp />
                    ) : (
                      <KeyboardArrowDown />
                    )}
                  </IconButton>
                </TableCell>
              </TableRow>

              {/* Collapsible Dropdown Row */}
              <TableRow>
                <TableCell
                  style={{ paddingBottom: 0, paddingTop: 0 }}
                  colSpan={4}
                >
                  <Collapse
                    in={openRowIndex === index}
                    timeout="auto"
                    unmountOnExit
                  >
                    <Box margin={2}>
                      {row.details?.map((detail, idx) => (
                        <div
                          key={idx}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginBottom: "0.5rem",
                          }}
                        >
                          <Typography
                            variant="bodySmall"
                            style={{ color: theme.palette.text.primary }}
                          >
                            {detail.name}
                          </Typography>
                          <Typography
                            variant="bodySmall"
                            style={{ color: theme.palette.text.secondary }}
                          >
                            {detail.value}%
                          </Typography>
                        </div>
                      ))}
                    </Box>
                  </Collapse>
                </TableCell>
              </TableRow>
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BrandTable;
