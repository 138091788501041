import React, { useEffect, useState } from "react";
import { ThemeProvider, Typography, Divider, Box } from "@mui/material";
import Grid from "@mui/material/Grid2";
import theme from "../../../theme";
import { globalStyles } from "../../../theme";
import GoBackHeader from "../../../Components/GoBackHeader/GoBackHeader";
import ContainerTitle from "../../../Components/ContainerTitle/ContainerTitle";
import BottleIcon from "../../../Assets/Icons/Bottle.png";
import useStyles from "./ViewCaptureStyles";
import ShelfIcon from "../../../Assets/Icons/Shelf.png";
import CouponIcon from "../../../Assets/Icons/coupon.png";
import CloseIcon from "../../../Assets/Icons/CloseRed.png";
import CheckIcon from "../../../Assets/Icons/check.png";
import AlertIcon from "../../../Assets/Icons/Alert.png";
import RedEmoji from "../../../Assets/Icons/RedEmoji.png";
import GreenEmoji from "../../../Assets/Icons/GreenEmoji.png";
import { useLocation } from "react-router-dom";

import { useStoreState } from "easy-peasy";

import { generateAnnotatedImage } from "./ImageUtils";
import RestApi from "../../../Utils/Backend";
import Locale from "../../../locale";

const skuDataTemp = {
  availabilityCompliance: {
    total: 7,
    passes: 0,
    units: [],
  },
  facingCompliance: {
    total: 14,
    passes: 0,
    detected: [
      { name: "Gold 250ml", count: 1 },
      { name: "Plus 250ml", count: 1 },
    ],
    requirements: [
      // {
      //   change: "+",
      //   amount: 3,
      //   units: [
      //     "Bottle 150ml",
      //     "Gold 325ml",
      //     "Gold 250ml",
      //     "Less Sugar 250ml",
      //     "Plus 250ml",
      //     "Apple Muscat 250ml",
      //     "Pamelo 250ml",
      //   ],
      // },
    ],
  },
};

const shelvingDataTemp = {
  status: 0,
  image: {
    roi: [],
  },
};

const POSMdataTemp = [
  {
    name: "Ambient compliance",
    total: 3,
    passed: 0,
    units: [
      {
        id: 1,
        name: "RB Generic",
        availability: 0,
      },
      {
        id: 2,
        name: "RBF A",
        availability: 0,
      },
      {
        id: 3,
        name: "RBF - Talent A",
        availability: 0,
      },
      {
        id: 4,
        name: "RBF - Talent B",
        availability: 0,
      },
      {
        id: 5,
        name: "RBF - Talent C",
        availability: 0,
      },
      {
        id: 6,
        name: "RBGL Generic A",
        availability: 0,
      },
      {
        id: 7,
        name: "RBGL Generic B",
        availability: 0,
      },
      {
        id: 8,
        name: "Weekly Icon",
        availability: 1,
      },
    ],
  },
  {
    name: "Chiller compliance",
    total: 3,
    passed: 0,
    units: [
      {
        id: 9,
        name: "RB Generic",
        availability: 0,
      },
      {
        id: 10,
        name: "Frame Generic",
        availability: 0,
      },
      {
        id: 11,
        name: "RBF Generic",
        availability: 0,
      },
      {
        id: 12,
        name: "RBGL Generic",
        availability: 0,
      },
      {
        id: 13,
        name: "RBGL Generic B",
        availability: 0,
      },
      {
        id: 14,
        name: " Thematic RBF TNG",
        availability: 0,
      },
    ],
  },
];

const useDimensions = () => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const calculateDimensions = () => {
      const maxWidth = window.innerWidth * 0.82;
      const maxHeight = window.innerHeight * 0.8;
      let width = maxWidth;
      let height = (width * 4) / 3;

      if (height > maxHeight) {
        height = maxHeight;
        width = (height * 3) / 4;
      }

      setDimensions({ width, height });
      console.log({ width, height });
    };

    calculateDimensions();
    window.addEventListener("resize", calculateDimensions);
    return () => window.removeEventListener("resize", calculateDimensions);
  }, []);

  return dimensions;
};

const base64ImageConverter = (img) => {
  return `data:image/png;base64,${img}`;
};

const AvailabilityComponent = ({ unit, locale }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        backgroundColor:
          unit.availability === 1
            ? theme.palette.success[100]
            : theme.palette.error[100],
        borderRadius: "0.375rem",
        padding: "0 0.5rem",
        justifyContent: "center",
      }}
    >
      <img
        src={unit.availability === 1 ? CheckIcon : CloseIcon}
        style={{ maxWidth: "0.875rem", margin: "0.25rem", padding: "0.15rem" }}
        alt={unit.name}
      />
      <Typography
        variant="caption"
        style={{
          color:
            unit.availability === 1
              ? theme.palette.success[300]
              : theme.palette.error[300],
        }}
      >
        {unit.availability === 1 ? locale.Found : locale.NotFound}
      </Typography>
    </div>
  );
};

const AvailabilityIcon = ({ unit }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        backgroundColor:
          unit.availability === 1
            ? theme.palette.success[200]
            : theme.palette.error[200],
        borderRadius: "2rem",
        padding: "0.15rem",
        justifyContent: "center",
      }}
    >
      <img
        src={unit.availability === 1 ? CheckIcon : CloseIcon}
        style={{
          maxWidth: "1rem",
          margin: "0.25rem",
          filter: "invert(100%) brightness(8)",
        }}
        alt={unit.name}
      />
    </div>
  );
};

const ViewCapture = () => {
  const location = useLocation();
  const classes = useStyles();
  const globalstyles = globalStyles();
  const locale = Locale();

  const { tabData } = location.state || {};
  const images = useStoreState((state) => state.images);
  const planogramData = useStoreState((state) => state.planogramData);
  const posmData = useStoreState((state) => state.posmData);

  const [annotatedImageSrc, setAnnotatedImageSrc] = useState(null);

  const [skuData, setSkuData] = useState(skuDataTemp);
  const [shelvingData, setShelvingData] = useState(shelvingDataTemp);

  const [POSMdata, setPOSMdata] = useState(POSMdataTemp);

  const dimensions = useDimensions();

  console.log("tabData", tabData);

  useEffect(() => {
    const updateSkuData = async () => {
      const skuImages = await RestApi().getSkuImages();

      if (!skuImages || skuImages.length === 0) {
        console.warn("No SKU images found");
        return;
      }

      // Filter skuImages to include only items where brand == "RedBull"
      const redBullSkuImages = skuImages.filter(
        (image) => image.brand === "RedBull"
      );

      // Create a set of image.class_id from redBullSkuImages
      const redBullClassIdSet = new Set(
        redBullSkuImages.map((image) => image.class_id)
      );

      // Calculate availabilityCompliance.passes: unique count of class_ids in classIdSet and < 8
      const availabilityComplianceSet = new Set(
        planogramData.class_ids.filter((id) => redBullClassIdSet.has(id))
      );

      const availabilityComplianceTotal = redBullClassIdSet.size;
      const availabilityCompliancePasses = availabilityComplianceSet.size;

      // Calculate facingCompliance.passes: total count of class_ids < 8
      const facingCompliancePasses = planogramData.class_ids.filter(
        (id) => id < 8
      ).length;

      // Create updatedUnits dynamically
      const updatedUnits = redBullSkuImages.map((image) => ({
        id: image.class_id, // Use class_id as id
        name: image.product_show_name, // Use product_name as name
        img: image.image_url, // Use image_url as img
        availability: planogramData.class_ids.includes(image.class_id) ? 1 : 0, // Set availability based on class_ids
      }));

      // Prepare facingCompliance.detected array, omitting class_ids > 7
      const classIdCounts = planogramData.class_ids.reduce((acc, id) => {
        if (id <= 7) {
          // Only include class_ids <= 7
          acc[id] = (acc[id] || 0) + 1;
        }
        return acc;
      }, {});

      const detectedArray = Object.keys(classIdCounts).map((id) => {
        const unit = updatedUnits.find((u) => u.id === Number(id));
        return {
          name: unit ? unit.name : `Unknown ID ${id}`,
          count: classIdCounts[id],
        };
      });

      // Update skuDataTemp with the new values
      const updatedSkuData = {
        ...skuDataTemp,
        availabilityCompliance: {
          ...skuDataTemp.availabilityCompliance,
          total: availabilityComplianceTotal,
          passes: availabilityCompliancePasses,
          units: updatedUnits,
        },
        facingCompliance: {
          ...skuDataTemp.facingCompliance,
          passes: facingCompliancePasses,
          detected: detectedArray,
        },
      };

      shelvingDataTemp.status = planogramData.status === true ? 0 : 1;
      setShelvingData(shelvingDataTemp);
      setSkuData(updatedSkuData);
    };

    updateSkuData();
  }, [planogramData]);

  // useEffect(() => {
  //   const updatePOSMData = async () => {
  //     // Fetch images for POSM data
  //     const posmImages = await RestApi().getPOSMImages();

  //     // Update POSMdataTemp with images and availability
  //     const updatedPOSMData = POSMdataTemp.map((complianceCategory) => ({
  //       ...complianceCategory,
  //       units: complianceCategory.units.map((unit) => ({
  //         ...unit,
  //         availability: posmData.class_ids.includes(unit.id) ? 1 : 0, // Set availability to 1 if id is in class_ids, else 0
  //         img: posmImages[unit.id] || "", // Assign image based on ID or empty string if not found
  //       })),
  //       passed: complianceCategory.units.reduce(
  //         (count, unit) =>
  //           count + (posmData.class_ids.includes(unit.id) ? 1 : 0),
  //         0
  //       ), // Count available units as passed
  //     }));

  //     setPOSMdata(updatedPOSMData);
  //   };

  //   updatePOSMData();
  // }, [posmData]);

  useEffect(() => {
    async function convertImage() {
      try {
        const finalImageSrc = await generateAnnotatedImage(
          planogramData,
          images[0][0]
        );
        setAnnotatedImageSrc(finalImageSrc);
      } catch (error) {
        console.error("Error generating annotated image:", error);
      }
    }
    convertImage();
  }, [planogramData, images?.[0]?.[0]]);

  return (
    <ThemeProvider theme={theme}>
      <GoBackHeader
        text={`${locale.ViewCapture} (${tabData.name})`}
        location={"/analysis"}
      />
      <center>
        <Typography variant="h2" className={classes.title}>
          {locale.AnalysisResults}
        </Typography>
      </center>
      <div className={globalstyles.mainContainer}>
        <div className={globalstyles.innerContainer}>
          <ContainerTitle title="SKU" icon={BottleIcon} images={images[0]} />
          <div className={classes.complianceContainer}>
            <Typography
              variant="titleSmall"
              style={{ color: theme.palette.decorative.darkblue }}
            >
              {locale.AvailabilityCompliance}
            </Typography>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Typography
                variant="h1"
                style={{
                  color:
                    skuData.facingCompliance.passes < 5
                      ? theme.palette.error[300]
                      : theme.palette.decorative.darkblue,
                }}
              >
                {skuData.availabilityCompliance.passes}
              </Typography>
              <Typography
                variant="h1"
                style={{ color: theme.palette.neutral[700] }}
              >
                /{skuData.availabilityCompliance.total}
              </Typography>
            </div>
            {/* <Grid container spacing={2}>
              {skuData.availabilityCompliance.units.map((unit, index) => (
                <Grid item xs={6} sm={6} md={6} key={index}>
                  <Box
                    sx={{
                      // display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "space-between",
                      backgroundColor:
                        unit.availability === 1
                          ? theme.palette.success[50]
                          : theme.palette.error[50],
                      padding: "1rem",
                      borderRadius: "0.375rem",
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                      width: "100%", // Ensures item fills the grid cell
                      height: "8rem", // Set consistent height for all items
                      boxSizing: "border-box", // Includes padding in height
                    }}
                  >
                    <img
                      src={unit.img}
                      alt="Unit Image"
                      style={{
                        maxWidth: "3.188rem",
                        height: "auto",
                        borderRadius: "0.375rem",
                        marginBottom: "0.5rem",
                      }}
                    />
                    <Typography
                      variant="titleSmall"
                      sx={{
                        color: theme.palette.neutral[700],
                        marginBottom: "0.375rem",
                        textAlign: "center",
                      }}
                    >
                      {unit.name}
                    </Typography>
                    <AvailabilityIcon unit={unit} />
                  </Box>
                </Grid>
              ))}
            </Grid> */}
            <Grid container spacing={3}>
              {skuData.availabilityCompliance.units.map((unit, index) => (
                <Grid size={{ xs: 6, md: 6 }} key={index}>
                  <Box
                    sx={{
                      // display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "space-between",
                      backgroundColor:
                        unit.availability === 1
                          ? theme.palette.success[50]
                          : theme.palette.error[50],
                      padding: "1rem",
                      borderRadius: "0.375rem",
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                      width: "100%", // Ensures item fills the grid cell
                      height: "8rem", // Set consistent height for all items
                      boxSizing: "border-box", // Includes padding in height
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid size={{ xs: 8, md: 8 }} key={index}>
                        <img
                          src={unit.img}
                          alt="Unit Image"
                          style={{
                            maxWidth: "3.188rem",
                            height: "auto",
                            borderRadius: "0.375rem",
                            marginBottom: "0.5rem",
                          }}
                        />
                      </Grid>
                      <Grid size={{ xs: 4, md: 4 }} key={index}>
                        <AvailabilityIcon unit={unit} />
                      </Grid>
                    </Grid>
                    <Typography
                      variant="titleSmall"
                      sx={{
                        color: theme.palette.neutral[700],
                        marginBottom: "0.375rem",
                        textAlign: "center",
                      }}
                    >
                      {unit.name}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </div>
          <Divider
            sx={{
              margin: "1.25rem 0",
              bgcolor: theme.palette.neutral[100],
            }}
          />
          <div className={classes.complianceContainer}>
            <Typography
              variant="titleSmall"
              style={{ color: theme.palette.decorative.darkblue }}
            >
              {locale.FacingCompliance}
            </Typography>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Typography
                variant="h1"
                style={{
                  color:
                    skuData.facingCompliance.passes < 5
                      ? theme.palette.error[300]
                      : theme.palette.decorative.darkblue,
                }}
              >
                {skuData.facingCompliance.passes}
              </Typography>
              <Typography
                variant="h1"
                style={{ color: theme.palette.neutral[700] }}
              >
                /{skuData.facingCompliance.total}
              </Typography>
            </div>
            <div
              className={globalstyles.greyContainer}
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "0.75rem",
              }}
            >
              <Typography
                variant="caption"
                style={{
                  color: theme.palette.neutral[400],
                }}
              >
                {locale.Detected}
              </Typography>
              {skuData.facingCompliance.detected.map((detected) => (
                <div
                  style={{
                    color: theme.palette.neutral[700],
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginTop: "0.5rem",
                  }}
                >
                  <Typography variant="bodySmall">{detected.name}</Typography>
                  <div>x {detected.count}</div>
                </div>
              ))}
            </div>
            {skuData.facingCompliance.requirements.map((requirement, index) => (
              <div
                className={globalstyles.redContainer}
                style={{ marginBottom: 0 }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={AlertIcon}
                    alt="Alert Icon"
                    className={globalstyles.alertIcon}
                  />
                  <Typography
                    variant="titleSmall"
                    style={{ color: theme.palette.error[300] }}
                  >
                    Please {requirement.change === "+" ? "add" : "remove"}{" "}
                    {requirement.amount} more items from below
                  </Typography>
                </div>
                <ul
                  style={{
                    margin: 0,
                    padding: "0 1.75rem",
                    color: theme.palette.error[400],
                  }}
                >
                  {requirement.units.map((unit) => (
                    <li style={{ marginTop: "0.5rem" }}>
                      <Typography variant="bodySmall">{unit}</Typography>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
        <div className={globalstyles.innerContainer}>
          <ContainerTitle title={locale.NoOfShelving} icon={ShelfIcon} />
          <div className={classes.complianceContainer}>
            <Typography
              variant="titleSmall"
              style={{ color: theme.palette.decorative.darkblue }}
            >
              {locale.ShelvingCompliance}
            </Typography>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                margin: "0.313rem 0",
              }}
            >
              <img
                src={shelvingData.status === 0 ? RedEmoji : GreenEmoji}
                alt="Poor Compliance"
                style={{ width: "2.5rem" }}
              />
              <Typography
                variant="h1"
                style={{
                  marginLeft: "0.5rem",
                  color:
                    shelvingData.status === 0
                      ? theme.palette.error[300]
                      : theme.palette.success[300],
                }}
              >
                {shelvingData.status === 0 ? locale.Poor : locale.Better}
              </Typography>
            </div>
            <img
              src={annotatedImageSrc}
              alt="Shelf Compliance"
              style={{
                width: `${dimensions.width}px`,
                height: `${dimensions.height}px`,
                marginTop: "0.5rem",
              }}
            />
            <div
              className={globalstyles.greyContainer}
              style={{ padding: "0.75rem" }}
            >
              <Typography
                variant="caption"
                style={{
                  color: theme.palette.neutral[400],
                  marginTop: "0.5rem",
                }}
              >
                {locale.ShelvingCompliance} {locale.Chart}
              </Typography>
              <div
                style={{
                  color: theme.palette.neutral[600],
                  marginTop: "0.5rem",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="bodySmall">{locale.Poor}</Typography>
                <Typography variant="bodySmall">{"(< 7 Facings)"}</Typography>
              </div>
              <div
                style={{
                  color: theme.palette.neutral[600],
                  marginTop: "0.5rem",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="bodySmall">{locale.Good}</Typography>
                <Typography variant="bodySmall">
                  {"(7 ~ 11 facings)"}
                </Typography>
              </div>
              <div
                style={{
                  color: theme.palette.neutral[600],
                  marginTop: "0.5rem",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="bodySmall">{locale.Better}</Typography>
                <Typography variant="bodySmall">
                  {"(12 ~ 13 facings)"}
                </Typography>
              </div>
              <div
                style={{
                  color: theme.palette.neutral[600],
                  marginTop: "0.5rem",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="bodySmall">{locale.Best}</Typography>
                <Typography variant="bodySmall">{"(> 14 facings)"}</Typography>
              </div>
            </div>
          </div>
        </div>
        <div className={globalstyles.innerContainer}>
          <ContainerTitle title="POSM" icon={CouponIcon} />
          {POSMdata.map((posm, index) => (
            <div className={classes.complianceContainer} key={index}>
              <Typography
                variant="titleSmall"
                style={{ color: theme.palette.decorative.darkblue }}
              >
                {posm.name}
              </Typography>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Typography
                  variant="h1"
                  style={{
                    color:
                      posm.passed < posm.total
                        ? theme.palette.error[300]
                        : theme.palette.decorative.darkblue,
                  }}
                >
                  {posm.passed}
                </Typography>
                <Typography
                  variant="h1"
                  style={{ color: theme.palette.neutral[700] }}
                >
                  /{posm.total}
                </Typography>
              </div>
              <div className={globalstyles.greyContainer} style={{ margin: 0 }}>
                {posm.units.map((unit) => (
                  <div
                    style={{
                      padding: "0.5rem",
                      backgroundColor: unit.availability
                        ? theme.palette.success[50]
                        : theme.palette.error[50],
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography variant="titleSmall">{unit.name}</Typography>
                      <AvailabilityComponent unit={unit} locale={locale} />
                    </div>
                    <img src={base64ImageConverter(unit.img)} />
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </ThemeProvider>
  );
};

export default ViewCapture;
