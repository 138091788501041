import { makeStyles } from "@mui/styles";
import Market from "../../Assets/Icons/Market.png";

const useStyles = makeStyles((theme) => ({
  mainPageShopContainer: {
    display: "flex",
    justifyContent: "flex-start",
    margin: "1rem",
    minHeight: "20.808rem",
    width: "23.45rem",
    backgroundImage: `url(${Market})`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  chillerImageButton: {
    position: "relative",
    top: "4.5rem",
    left: "10.2rem",
    width: "2.5rem",
    height: "2.5rem",
    // "&:hover": {
    border: "5px solid rgba(255, 255, 255, 0.5)",
    background: theme.palette.primary.main + "70",
    borderRadius: "50%",
    backdropFilter: "blur(2px)",
    // },
  },
  shelveImageButton: {
    position: "relative",
    top: "11.6rem",
    left: "9rem",
    width: "2.5rem",
    height: "2.5rem",
    border: "5px solid rgba(255, 255, 255, 0.5)",
    background: theme.palette.primary.main + "70",
    borderRadius: "50%",
    backdropFilter: "blur(2px)",
  },
  blockDisImageButton: {
    position: "relative",
    top: "15.4rem",
    left: "3.6rem",
    width: "2.5rem",
    height: "2.5rem",
    border: "5px solid rgba(255, 255, 255, 0.5)",
    background: theme.palette.primary.main + "70",
    borderRadius: "50%",
    backdropFilter: "blur(2px)",
  },
  posterImageButton: {
    position: "relative",
    top: "4.4rem",
    left: "3.6rem",
    width: "2.5rem",
    height: "2.5rem",
    border: "5px solid rgba(255, 255, 255, 0.5)",
    background: theme.palette.primary.main + "70",
    borderRadius: "50%",
    backdropFilter: "blur(2px)",
  },
}));

export default useStyles;
