import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  modalText: {
    color: theme.palette.neutral[600],
    marginBottom: "0.5rem !important",
  },
  chillerSelect: {
    marginTop: "1.5rem !important",
    marginBottom: "1rem !important",
  },
  modalButton:{
    marginTop: "1rem !important",
    backgroundColor: theme.palette.primary.main,
    color: "white",
    textTransform: "none !important"
  }
}));

export default useStyles;
