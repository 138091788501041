import React, { useState, useRef, useEffect } from "react";
import theme from "../../../theme";
import { ThemeProvider } from "@mui/styles";
import { Typography, Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import Fade from "@mui/material/Fade";
import { useStoreState, useStoreActions } from "easy-peasy";
import Modal from "@mui/material/Modal";
import Locale from "../../../locale";
import useStyles from "./cameraModalStyles";
import Webcam from "react-webcam";
import Frame from "../../../Assets/Icons/range.png";
import CaptureButton from "../../../Assets/Icons/Capture_button.png";
import CameraIcon from "../../../Assets/Icons/Camera.png";
import HelpIcon from "./../../../Assets/Icons/Help.png";
import CaptureGuideModal from "../CaptureGuide/CapGuide";

const useDimensions = () => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const calculateDimensions = () => {
      const maxWidth = window.innerWidth;
      const maxHeight = window.innerHeight * 0.8;
      let width = maxWidth;
      let height = (width * 4) / 3;

      if (height > maxHeight) {
        height = maxHeight;
        width = (height * 3) / 4;
      }

      setDimensions({ width, height });
      console.log({ width, height });
    };

    calculateDimensions();
    window.addEventListener("resize", calculateDimensions);
    return () => window.removeEventListener("resize", calculateDimensions);
  }, []);

  return dimensions;
};

const ConfirmationModal = ({ open, onClose, locale, handleClose }) => {
  const classes = useStyles();

  return (
    <Modal open={open} onClose={handleClose}>
      <Fade in={open}>
        <div
          className={classes.container}
          style={{
            outline: "none",
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            maxWidth: "30rem",
            backgroundColor: "white",
            borderRadius: "1rem",
            margin: "0 auto",
            padding: "1.5rem",
            height: "auto",
          }}
        >
          <div className={classes.header} style={{ marginBottom: "1.2rem" }}>
            <div className={classes.title}>
              <Typography variant="h3" style={{ textAlign: "left" }}>
                {locale.ImageCancelTitle}
              </Typography>
            </div>
          </div>
          <div>
            <Typography
              variant="bodySmall"
              style={{ color: theme.palette.neutral[700], textAlign: "left" }}
            >
              {locale.ImageCancelText}
            </Typography>
          </div>
          <div
            className={classes.buttonContainer}
            style={{ justifyContent: "flex-end", margin: "1rem 0 0" }}
          >
            <Button
              className={classes.leaveModalButton1}
              variant="outlined"
              onClick={() => {
                handleClose();
                onClose();
              }}
            >
              <Typography variant="titleSmall">{locale.Leave}</Typography>
            </Button>

            <Button
              className={classes.leaveModalButton2}
              variant="contained"
              onClick={handleClose}
            >
              <Typography variant="titleSmall">
                {locale.ContinueCapture}
              </Typography>
            </Button>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

const CameraModal = ({ open, onClose, modalTitle, picIndex }) => {
  const locale = Locale();
  const images = useStoreState((state) => state.images);
  const setImage = useStoreActions((actions) => actions.addImage);
  const removeImage = useStoreActions((actions) => actions.removeImage);
  const classes = useStyles();
  const webcamRef = useRef(null);
  const dimensions = useDimensions();

  const [closeModal, setCloseModal] = useState(false);
  const [captureModalOpen, setCaptureModalOpen] = useState(false);

  const handleCapture = () => {
    setImage({
      index: picIndex[0],
      index2: picIndex[1],
      value: webcamRef.current.getScreenshot({
        height: 1200,
        width: 1600,
      }),
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <CaptureGuideModal
        open={captureModalOpen}
        onClose={() => setCaptureModalOpen(false)}
      />
      <ConfirmationModal
        open={closeModal}
        onClose={onClose}
        locale={locale}
        handleClose={() => setCloseModal(false)}
      />
      <Dialog
        fullScreen
        open={open}
        onClose={onClose}
        closeAfterTransition
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <div className={classes.container}>
            <div className={classes.header}>
              <div
                className={classes.cancelButton}
                onClick={() => setCloseModal(true)}
              >
                <Typography variant="titleSmall">
                  {locale.ImageCancel}
                </Typography>
              </div>
              <div className={classes.title}>
                <Typography variant="h3">{modalTitle}</Typography>
              </div>
              <div>
                <img
                  src={HelpIcon}
                  alt="Help Icon"
                  style={{
                    width: "1.25rem",
                    height: "1.25rem",
                    marginRight: "0.5rem",
                    zIndex: 1000,
                    position: "relative",
                  }}
                  onClick={() => setCaptureModalOpen(true)}
                />
              </div>
            </div>
            {!images?.[picIndex?.[0]]?.[picIndex?.[1]] ? (
              <center>
                <div style={{ width: "100%", position: "relative" }}>
                  <img
                    src={Frame}
                    alt="Frame"
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      width: "85%",
                      height: "90%",
                      transform: "translate(-50%, -50%)",
                      zIndex: 1000,
                      pointerEvents: "none",
                    }}
                  />
                  <Webcam
                    ref={webcamRef}
                    forceScreenshotSourceSize
                    videoConstraints={{
                      height: 1200,
                      width: 1600,
                      facingMode: "environment",
                    }}
                    width={dimensions.width}
                    height={dimensions.height}
                  />
                </div>
                <img
                  src={CaptureButton}
                  alt="capture"
                  style={{
                    width: "3.75rem",
                    height: "3.75rem",
                    margin: "1rem 0",
                  }}
                  onClick={handleCapture}
                />
              </center>
            ) : (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: `${dimensions.width}px`, // Parent matches image width
                    height: `${dimensions.height}px`, // Parent matches image height
                    backgroundColor: "#f0f0f0",
                    overflow: "hidden", // Prevents overflow if the image exceeds container size
                  }}
                >
                  <img
                    style={{
                      width: `${dimensions.width}px`,
                      height: `${dimensions.height}px`,
                    }}
                    src={images?.[picIndex?.[0]]?.[picIndex?.[1]] || ""}
                    alt="LastPhoto"
                  />
                </div>
                <div className={classes.buttonContainer}>
                  <Button
                    className={classes.postCameraButtons}
                    variant="outlined"
                    onClick={() => {
                      removeImage({
                        index: [picIndex[0]],
                        index2: [picIndex[1]],
                      });
                    }}
                  >
                    <img
                      alt="Recapture"
                      src={CameraIcon}
                      style={{ marginRight: "0.75rem", width: "1.25rem" }}
                    />
                    <Typography variant="titleSmall">
                      {locale.Recapture}
                    </Typography>
                  </Button>

                  <Button
                    sx={{
                      backgroundColor: `${theme.palette.primary[400]} !important`,
                      color: "white !important",
                    }}
                    className={classes.postCameraButtons}
                    variant="contained"
                    onClick={onClose}
                  >
                    <Typography variant="titleSmall">
                      {locale.SaveAndContinue}
                    </Typography>
                  </Button>
                </div>
              </>
            )}
          </div>
        </Fade>
      </Dialog>
    </ThemeProvider>
  );
};

export default CameraModal;
