import React from "react";
import { PieChart } from "@mui/x-charts";
import { ThemeProvider } from "@mui/styles";
import theme from "../../../theme";
import { Typography } from "@mui/material";

const DynamicPieChart = ({ data, selectedBrand, brandLogos }) => {
  const prepareChartData = () => {
    if (selectedBrand === null) {
      // No brand selected: Display brands with their total facing
      return data.map((item) => ({
        id: item.label,
        name: item.label, // Brand name
        value: item.facing,
        color: item.color,
      }));
    } else {
      // A brand is selected: Show its products and other brands
      return data.flatMap((item) => {
        if (item.label === selectedBrand) {
          // Show individual products for the selected brand
          return item.details.map((product) => ({
            id: product.name,
            name: `${item.label}: ${product.name}`, // Product with brand name
            value: product.facing,
            color: product.color,
          }));
        }
        // Show other brands but gray them out
        return {
          id: item.label,
          name: item.label, // Brand name
          value: item.facing,
          color: "#d3d3d3", // Gray color for other brands
        };
      });
    }
  };

  const getCenterContent = () => {
    if (selectedBrand === null) {
      // Total facing for all brands
      const totalFacing = data.reduce((sum, item) => sum + item.facing, 0);
      return (
        <div style={{ textAlign: "center" }}>
           <Typography variant="h2">{totalFacing}</Typography>
          <Typography variant="bodySmall">Total Facing</Typography>
        </div>
      );
    } else {
      // Total facing for selected brand with logo
      const selectedBrandData = data.find(
        (item) => item.label === selectedBrand
      );
      return (
        <ThemeProvider theme={theme}>
          <div style={{ textAlign: "center" }}>
            <img
              src={brandLogos[selectedBrand]} // Brand logo
              alt={selectedBrand}
              style={{ height: "60px", marginBottom: "5px" }}
            />
            <p style={{ fontSize: "1.5rem", margin: 0 }}>
              {selectedBrandData.facing}
            </p>
            <Typography variant="bodySmall">Facing</Typography>
          </div>
        </ThemeProvider>
      );
    }
  };

  const chartData = prepareChartData();

  return (
    <div style={{ position: "relative", width: "200px", height: "200px" }}>
      {/* Pie Chart */}
      <PieChart
        series={[
          {
            data: chartData,
            innerRadius: 79,
            outerRadius: 100,
            paddingAngle: 1,
            cornerRadius: 4,
            startAngle: 0,
            endAngle: 360,
            cx: 95,
            label: (params) => `${params.name} (${params.value})`, // Label for each segment
            colors: chartData.map((item) => item.color),
          },
        ]}
        height={200}
        width={200}
      />
      {/* Center Content */}
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {getCenterContent()}
      </div>
    </div>
  );
};

export default DynamicPieChart;
