import { createStore, action } from "easy-peasy";

const MainStore = createStore({
  //Locale
  locale: "EN",
  setLocale: action((state, payload) => {
    state.locale = payload;
  }),

  images: [[]],

  addImage: action((state, payload) => {
    const { index, index2, value } = payload;
    console.log(payload);

    // // Expand the outer array if needed
    // while (index >= state.images.length) {
    //   state.images.push([]);
    // }

    // Ensure the target index within the inner array exists and is an array
    if (!Array.isArray(state.images[index][index2])) {
      state.images[index][index2] = null;
    }

    // Directly mutate the inner array
    state.images[index][index2] = value;
  }),

  // Remove image at a specific index
  removeImage: action((state, payload) => {
    const { index, index2 } = payload;
    console.log(index, index2);
    console.log(state.images);

    if (state.images[index] && state.images[index][index2]) {
      state.images[index][index2] = null;
    }
  }),

  // Optional: If you want to add multiple images at once, you can use a separate action for that:
  addImages: action((state, payload) => {
    state.images = [...state.images, ...payload]; // Adds multiple images
  }),

  sessionId: "",
  setSessionId: action((state, payload) => {
    state.sessionId = payload;
  }),

  planogramData: {},
  setPlanogramData: action((state, payload) => {
    state.planogramData = payload;
  }),

  posmData: {},
  setPosmData: action((state, payload) => {
    state.posmData = payload;
  }),

  cameraModalState: [[false, false]],

  setCameraModalState: action((state, payload) => {
    const { index1, index2, value } = payload;
    console.log(payload);
    if (
      index1 < state.cameraModalState.length &&
      index2 < state.cameraModalState[index1].length
    ) {
      state.cameraModalState[index1][index2] = value;
    }
  }),

  clearCameraModalState: action((state, index1, index2) => {
    if (
      index1 < state.cameraModalState.length &&
      index2 < state.cameraModalState[index1].length
    ) {
      state.cameraModalState[index1][index2] = false;
    }
  }),

  clearAllCameraModalStates: action((state) => {
    state.cameraModalState = [];
  }),
});

export default MainStore;
