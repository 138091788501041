import { ThemeProvider, Typography } from "@mui/material";
import React, { useState } from "react";
import theme, { globalStyles } from "../../../theme";
import { useStoreState } from "easy-peasy";
import { Button } from "@mui/material";
import Locale from "../../../locale";
import { useNavigate } from "react-router-dom";
import Lottie from "lottie-react";
import LoadingIcon from "../../../Assets/Animations/Loading.json";

const HomeAnalyze = () => {
  const [loading, setLoading] = useState(false);
  const analyzeImages = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      navigate("/analysis");
    }, 5000);
  };
  const navigate = useNavigate();
  const locale = Locale();
  const images = useStoreState((state) => state.images);
  const globalstyles = globalStyles();
  return (
    <ThemeProvider theme={theme}>
      <div style={{ margin: "1rem" }}>
        <Button
          disabled={
            !(images[0]?.length > 1 && images[0]?.every((image) => image !== null))
          }
          variant="contained"
          onClick={() => {
            analyzeImages();
          }}
          className={
            loading
              ? globalstyles.analyzeButtonLoading
              : globalstyles.analyzeButton
          }
          style={{
            display: "flex",
            alignItems: "stretch",
            padding: "0.5rem 1rem",
          }}
        >
          <Typography variant="titleSmall">
            {loading ? locale.AnalyzingButton : locale.AnalyzeButton}
          </Typography>
          {loading && (
            <Lottie
              animationData={LoadingIcon}
              loop={true}
              style={{ width: "1.2rem", marginLeft: "0.5rem",marginBottom:"-0.25rem" }}
            />
          )}
        </Button>
      </div>
    </ThemeProvider>
  );
};

export default HomeAnalyze;
