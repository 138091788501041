import { createTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#2267DD", // Primary - 500 Text
      light: "#E1E5FA", // Primary - 100 Button Stroke
      dark: "#0031A4", // Darker blue
      500: "#02207D",
      400: "#194FF0",
      300: "#5A98EE",
      200: "#CCD7F6",
      100: "#E1E5FA",
      50: "#F0F4FF",
    },
    neutral: {
      main: "#344054", // Neutral - 500
      700: "#1D2939",
      600: "#344054",
      500: "#667085",
      400: "#8791A0",
      300: "#9BA3AB",
      200: "#EAECF0",
      100: "#F2F4F7",
      50: "#F8F8FA",
      25: "#FFFFFF",
    },
    decorative: {
      darkblue: "#0035D4",
      babyBlue: "#3FC2F1",
      tiffanyBlue: "#0BBDDC",
    },
    error: {
      main: "#E03100", // Error - 300 Text
      light: "#FFE6E6", // Error - 50 Background
      dark: "#B82A00", // Darker error shade
      400: "#770100",
      300: "#DC2625",
      200: "#FC6B6A",
      100: "#F7B7B2",
      50: "#FFE6E6",
    },
    success: {
      main: "#0BAC3A", // Success - 400 Text
      light: "#E5F8E6", // Success - 50 Background
      dark: "#008C29", // Darker success shade
      400: "#0BAC3A",
      300: "#008E5B",
      200: "#5FD192",
      100: "#CFF3DC",
      50: "#E5F8E6",
    },
    warning: {
      main: "#BF7500", // Warning - 300 Text
      light: "#FFF7DA", // Warning - 50 Background
      dark: "#A95A00", // Darker warning shade
      300: "#BF7500",
      200: "#FDA213",
      100: "#F7D87F",
      50: "#FFF7DA",
    },
    opacity: {
      black: {
        90: "rgba(0, 0, 0, 0.9)",
        80: "rgba(0, 0, 0, 0.8)",
        50: "rgba(0, 0, 0, 0.5)",
        40: "rgba(0, 0, 0, 0.4)",
        30: "rgba(0, 0, 0, 0.3)",
        20: "rgba(0, 0, 0, 0.2)",
        10: "rgba(0, 0, 0, 0.1)",
      },
      white: {
        90: "rgba(255, 255, 255, 0.9)",
        80: "rgba(255, 255, 255, 0.8)",
        50: "rgba(255, 255, 255, 0.5)",
        40: "rgba(255, 255, 255, 0.4)",
        30: "rgba(255, 255, 255, 0.3)",
        20: "rgba(255, 255, 255, 0.2)",
        10: "rgba(255, 255, 255, 0.1)",
      },
    },
  },
  typography: {
    fontFamily: [
      '"Albert Sans"', // Primary font
      '"Inter"', // Secondary font
      "sans-serif",
    ].join(","),

    h1: {
      fontFamily: '"Albert Sans", sans-serif',
      fontSize: "28px",
      fontWeight: 900,
      lineHeight: 1.5, // 150%
      letterSpacing: "0%",
    },
    h2: {
      fontFamily: '"Albert Sans", sans-serif',
      fontSize: "24px",
      fontWeight: 700,
      lineHeight: 1.5,
      letterSpacing: "0%",
    },
    h3: {
      fontFamily: '"Albert Sans", sans-serif',
      fontSize: "20px",
      fontWeight: 700,
      lineHeight: 1.5,
      letterSpacing: "0%",
    },
    titleLarge: {
      fontFamily: '"Inter", sans-serif',
      fontSize: "18px",
      fontWeight: 600,
      lineHeight: 1.5,
      letterSpacing: "0%",
    },
    titleMedium: {
      fontFamily: '"Inter", sans-serif',
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: 1.5,
      letterSpacing: "0%",
    },
    titleSmall: {
      fontFamily: '"Inter", sans-serif',
      fontSize: "14px",
      fontWeight: 600,
      lineHeight: 1.5,
      letterSpacing: "0%",
    },
    bodyLarge: {
      fontFamily: '"Inter", sans-serif',
      fontSize: "18px",
      fontWeight: 500,
      lineHeight: 1.5,
      letterSpacing: "0%",
    },
    bodyMedium: {
      fontFamily: '"Inter", sans-serif',
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: 1.5,
      letterSpacing: "0%",
    },
    bodySmall: {
      fontFamily: '"Inter", sans-serif',
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: 1.5,
      letterSpacing: "0%",
    },
    caption: {
      fontFamily: '"Inter", sans-serif',
      fontSize: "12px",
      fontWeight: 500,
      lineHeight: 1.5,
      letterSpacing: "0%",
    },
  },
});

const globalStyles = makeStyles((theme) => ({
  headerBox: {
    display: "flex",
    alignItems: "center",
    borderColor: "white",
    border: "1px solid",
    borderRadius: "0.313rem",
    padding: "0 0.625rem",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent", // Adjust the border color on focus
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent", // Border color on hover
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent", // Border color when focused
    },
  },
  headerAppBar: {
    backgroundColor: `${theme.palette.decorative.darkblue} !important`,
    minHeight: "4.813rem",
    width: "100%",
    justifyContent: "center",
  },
  headerLanguageSelect: {
    margin: "-0.838rem -0.625rem -0.838rem 0",
    color: "white", // Text color
    border: "1px solid", // Border style
    borderColor: "transparent", // Custom border color from theme
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent !important", // Adjust the border color on focus
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent !important", // Border color on hover
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent !important", // Border color when focused
    },
  },
  footerText: {
    width: "100vw",
    textAlign: "center",
    color: theme.palette.neutral[400],
    paddingBottom: "1rem",
  },
  mainContainer: {
    margin: "0 1rem",
  },
  innerContainer: {
    padding: "1rem",
    backgroundColor: theme.palette.neutral[25],
    borderRadius: "1rem",
    margin: "1.25rem 0",
  },
  greyContainer: {
    backgroundColor: theme.palette.neutral[100],
    margin: "0.75rem 0 0 0",
    borderRadius: "0.5rem",
    padding: "0.75rem 0",
  },
  redContainer: {
    backgroundColor: theme.palette.error[50],
    padding: "0.5rem",
    borderRadius: "0.5rem",
    margin: "0.813rem 0",
  },
  alertIcon: {
    width: "1.25rem",
    height: "1.25rem",
    marginRight: "0.5rem",
  },
  analyzeButton: {
    backgroundColor: `${theme.palette.primary[400]} !important`,
    color: "white",
    textTransform: "none !important",
    width: "100%",
  },
  analyzeButtonLoading: {
    backgroundColor: `${theme.palette.primary[300]} !important`,
    color: "white",
    textTransform: "none !important",
    width: "100%",
  },
  modalContainer: {
    outline: "none",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: "21rem",
    backgroundColor: "white",
    borderRadius: "1rem",
    padding: "1rem",
    scrollY: "auto"
  },
}));

export default theme;
export { globalStyles };
