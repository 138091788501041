import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "1.2rem",
    backgroundColor: theme.palette.neutral[25],
    borderRadius: "0.75rem",
    border: `1px solid ${theme.palette.primary[200]}`,
    margin: "1rem 0",
  },

  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
  },

  column: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginRight: "0.5rem",
  },

  circle: {
    backgroundColor: theme.palette.primary[100],
    padding: "0.7rem",
    borderRadius: "50px",
    marginBottom: "-1.25rem",
  },

  description: {
    textAlign: "left",
    color: theme.palette.neutral[500],
    margin: "1rem 0 0",
  },

  helpContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },

  helpRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },

  helpText: {
    color: theme.palette.neutral[500],
  },

  helpButtonText: {
    color: theme.palette.primary[400],
    marginRight: "0.5rem",
  },

  selectContainer: {
    width: "100%",
    margin: "1rem 0 0",
    textAlign: "left",
  },

  priceTagContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    margin: "1rem 0 0",
  },
  checkbox: {
    marginRight: "0.564rem",
  },
  postCameraButtons: {
    display: "flex",
    width: "100%",
    justifyContent: "space-around",
  },
}));

export default useStyles;
